body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  max-width: 100vw;
  height: fit-content;
  min-height: 100vh;
  overflow-x: hidden;
}

html {
  font-size: 10px;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --main-white: #f4f4f4;
  --main-black: rgb(40,40,40);
  --main-green: rgb(64, 196, 99);
  --main-blue: #007acc;
  --second-white: #fff;
  --main-grey: rgba(0,0,0,0.1)
}