.App {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

}


/* Reset some default browser styles */
body, h1, h2, h3, p, ul {
  margin: 0;
  padding: 0;
}

/* Set a background color and font for the entire page */
body {
  font-family: Arial, sans-serif;
  background-color: var(--main-white);
  color: var(--main-black);
  line-height: 1.6;
}

header, section, footer {
  width: 100%;
}

/* Add spacing around the header and apply a background color */
header {
  background-color: var(--main-green);
  padding: 2rem;
  color: var(--main-black);
  text-align: center;
}

/* Style the h1 in the header */
header h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

/* Style the links in the header */
header a {
  color: var(--main-black);
  text-decoration: underline;
}

section {
  width: 90%;
}

/* Style the summary section */
.summary {
  background-color: var(--second-white);
  padding: 2rem;
  margin: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.4rem var(--main-grey);
}

/* Style the skills section */
.skills {
  background-color: var(--second-white);
  padding: 2rem;
  margin: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.4rem var(--main-grey);
}

/* Style the experience section */
.experience {
  background-color: var(--second-white);
  padding: 2rem;
  margin: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.4rem var(--main-grey);
}

/* Style each experience item */
.experience-item {
  margin-bottom: 2rem;
}

/* Style the education section */
.education {
  background-color: var(--second-white);
  padding: 2rem;
  margin: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.4rem var(--main-grey);
}

/* Style each education item */
.education-item {
  margin-bottom: 2rem;
}

/* Style the footer */
footer {
  background-color: var(--main-green);
  color: var(--main-black);
  padding: 1rem;
  text-align: center;
}

/* Style headings */
h2 {
  font-size: 2.4rem;
  margin-top: 2rem;
}

h3 {
  font-size: 1.8rem;
  margin-top: 1.5rem;
}

p, ul {
  font-size: 1.5rem;
}

/* Style unordered lists */
ul {
  list-style-type: disc;
  margin-left: 2rem;
}

/* Add media query for responsiveness */
@media (max-width: 768px) {
  header h1 {
      font-size: 2.2rem;
  }

  .summary, .skills, .experience, .education {
      margin: 1rem;
      padding: 1.5rem;
  }
}
